const subConfig =
  REACT_APP_ENV === 'live'
    ? {
        appId: '85e80adc3795147182c681ce7e69cbb9',
        secretKey: 'debd7036cbd4a88c1dd0f5cb83c0e832',
        publicKey:
          '-----BEGIN PUBLIC KEY-----' +
          'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAssAOqkyTje' +
          'H2A5OJBQducDPKC/J06hSraWQDvVtlVCPAqa8Jqnsv1x5Kb4i69WM7' +
          'GAis/EsjPYKEyxy6JEcCiZPqDpK2Poi8CwOvknD+reSQ9aCbqWkGPi' +
          'eRc8mYq8LgTzZtp0B2iiv4IcYHCWLdj/5lUIMmLe7fdMP9wo5+W3Xl' +
          'pBAcHyH+VJ3xzq/AaSR1JM4XEvQSLoMSnI2SW9Tusl3feUEB/iJVpu' +
          'oZ7lm9zWwcHmrHhCCuXuxdAoz6tG4jFBa2qK/OaI/EYzftBBsjdaH0' +
          'Od3IZUHfSJ2GHhzo1CEXONs8PpM1m47yaNVHhhMS/TZRR1url4/pR0' +
          'DwA7xFAwIDAQAB' +
          '-----END PUBLIC KEY-----',
      }
    : {
        appId: 'abf227432f9935a0a0dad51c5338a825',
        secretKey: '36f752f5c3733f83e3b0484c340bfd90',
        publicKey:
          '-----BEGIN PUBLIC KEY-----' +
          'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArnOzprxuryHHITI9oAL4' +
          '7FERvLyX5wPbE5Qy0q4vwnlj53zvAWXFfJ56/HJ9uoUMMu3Nz20SKt2vkXZ+1bcT' +
          'u3gUVnLZt+8c1Qmt2x5UFSj+Ip9Muc0CFr16ZYx9FMitabZO3dbe0qUH1+1x04Pf' +
          'pHDw2yCF4KpLTDfMmPBbmbs85RG1X6a0TrTpaFmvM+kl8Xr/yf3pamnGEaDNIBnL' +
          'WfR1er308DBXETOef3enx2/derbLJiQsWNQ3xWZZmhcUzRA6CuES/yZPndsmSIJp' +
          'CSxqPKqhibfVFacHYfXJ2PzDzVXM9SBp5lLZLkRI5L0rLq8CPQ8lULFDnvV0KmF9' +
          'WQIDAQAB' +
          '-----END PUBLIC KEY-----',
      };

const envConfig = (() => {
  // mock, dev, test1, reg
  if (['mock', 'dev', 'test1', 'reg'].indexOf(REACT_APP_ENV) >= 0) {
    let env = 'env3';
    switch (REACT_APP_ENV) {
      case 'reg':
        env = 'reg';
        break;
      case 'test1':
        env = 'env1';
        break;
      case 'dev':
      case 'mock':
      default:
        env = 'env3';
        break;
    }
    // const env = REACT_APP_ENV === 'test1' ? 'env1' : 'env3';
    return {
      iconPreUrl: `https://fastescrow-h5-${env}.test.scrediario.com.br`,
    };
  }

  // live
  if (REACT_APP_ENV === 'live') {
    return {
      iconPreUrl: `https://cdn.scredit.sg/${BIZ_COUNTRY}/fastescrow-h5/live`,
    };
  }
  // stable
  if (REACT_APP_ENV === 'stable') {
    return {
      iconPreUrl: `https://fastescrow-h5.test-stable.scrediario.com.br`,
    };
  }
  // test, uat
  return {
    iconPreUrl: `https://fastescrow-h5.${REACT_APP_ENV}.scrediario.com.br`,
  };
})();

const requestHeaderConfig = (() => {
  switch (REACT_APP_ENV) {
    case 'mock':
    case 'dev':
    case 'stable':
    case 'test':
    case 'test1':
    case 'reg':
      return {
        Authorization:
          'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIxIiwiZXhwIjoxOTQyOTE0NjY5LCJqdGkiOiJmZjQzOTRhZC0zMjBjLTQ3ZTAtODA4OS1kY2YyNGY4YmZkZTMiLCJpYXQiOjE2Mjc1NTQ2NjksImlzcyI6ImZhc3QtZXNjcm93LWlkLWdhdGV3YXkiLCJzdWIiOiJ7XCJTb3VyY2VcIjoxfSJ9.d4TWrRTl0AjM3SEbGmzrOylx5oeGYkyqJsLK7i8Uk-FH3UJwaD9FUGrdXM-OdoHB7hq2X1R6qZ5IGbS3uaJ14w',
      };
    case 'uat':
      return {
        Authorization:
          'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIxIiwiZXhwIjoxOTQ3MTI4ODQ1LCJqdGkiOiIxYTRlYTRhMi1lZjIwLTRhYWQtODU2Mi0zMzBhZGQ0NzQxZjciLCJpYXQiOjE2MzE3Njg4NDUsImlzcyI6ImZhc3QtZXNjcm93LWlkLWdhdGV3YXkiLCJzdWIiOiJ7XCJTb3VyY2VcIjoxfSJ9.e9puWfvwrXpQFhbiy8RKjxT66xoMTmto7cOtTbN8oNiPHpOrRsDN4mQrpBoiPUuF_EOqQ-F8c9Xmu5F3zSB93g',
      };
    case 'live':
    default:
      return {
        Authorization:
          'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIxIiwiZXhwIjoxOTQ4MjQxMzAyLCJqdGkiOiJlNmI2NDcxYy00YWFkLTRmZDctYTZhMi1iZTIwMGE5MmI0MGEiLCJpYXQiOjE2MzI4ODEzMDIsImlzcyI6ImZhc3QtZXNjcm93LWlkLWdhdGV3YXkiLCJzdWIiOiJ7XCJTb3VyY2VcIjoxfSJ9.3_4nP2B4CkBZ_WcmEWPOdHh7UZLFeSI_by2P2t1nN741eQTODebwQUpueop6fnRFasq62N-zdIYuwGE9Wc9v6g',
      };
  }
})();

const dependDomain = (() => {
  switch (REACT_APP_ENV) {
    case 'mock':
    case 'dev':
      return {
        ucDomain: 'https://uc-h5-env3.test.scrediario.com.br',
        financeDomain: 'https://finance-env3.test.scrediario.com.br',
      };
    // TODO: need to update
    case 'stable':
      return {
        ucDomain: 'https://uc-h5.test-stable.scrediario.com.br',
        financeDomain: 'https://finance.test-stable.scrediario.com.br',
      };
    case 'test':
      return {
        ucDomain: 'https://uc-h5.test.scrediario.com.br',
        financeDomain: 'https://finance.test.scrediario.com.br',
      };
    case 'test1':
      return {
        ucDomain: 'https://uc-h5-env1.test.scrediario.com.br',
        financeDomain: 'https://finance-env1.test.scrediario.com.br',
      };
    case 'reg':
      return {
        ucDomain: 'https://uc-h5-reg.test.scrediario.com.br',
        financeDomain: 'https://finance-reg.test.scrediario.com.br',
      };
    case 'uat':
      return {
        ucDomain: 'https://uc-h5.uat.scrediario.com.br',
        financeDomain: 'https://finance.uat.scrediario.com.br',
      };
    case 'live':
    default:
      return {
        ucDomain: 'https://uc-h5.scrediario.com.br',
        financeDomain: 'https://finance.scrediario.com.br',
      };
  }
})();

const config = {
  platForm: 1,
  version: 1,
  region: 'br',
  fastEscrowVersion: 'fastescrow_v1.0.0.100',
  ...subConfig,
  ...envConfig,
  ...requestHeaderConfig,
  ...dependDomain,
};

export default config;
